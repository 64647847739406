<template>
  <div
    class="container-fluid"
    style="max-width: 1300px"
    :class="{
      'py-0': page.container,
    }"
  >
    <div class="row pb-5" >
      <div class="col-12" v-if="statusCode == 404">
        <not-found @gohome="gohome" />
      </div>
      <div class="col-12" v-else>
        <b-overlay :show="loading" class="p-0" blur="5px">
          <div v-if="page.slider">
            <slider :sliders="page.sliders" />
          </div>
          <h2 class="text-center my-4 pb-3 font-weight-bold text-uppercase" v-if="page.showTitle">
            {{ page.title }}
          </h2>
          <div class="row d-none1" v-html="getContent"></div>

          <div class="mt-0">
            <div
              v-for="(item, i) in page.components"
              class="content c0"
              :key="`item-${i}`"
            >
              <components :item="item" />
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
//import Product from "./Product";
// import Slider from "../components/Slider";
// import components from "../components/components";
// import NotFound from "./NotFound"

const Product = () => import("./Product");
const Slider = () => import("../components/Slider");
const components = () => import("../components/components");
const NotFound = () => import("./NotFound");

export default {
  components: {
    Product,
    Slider,
    components,
    NotFound
  },
  data() {
    return {
      page: {},
      loading: true,
      chosenComponent: "CustomPage",
      statusCode: 200,
    };
  },
  mounted() {
    console.log("Loading page...");
    this.load();
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    getContent() {
      let content = this.page.content;

      if (!content) return "";

      //replace all [LINK TO="url"] to <router-link to="url">
      content = content.replace(/\[LINK TO="(.*?)"]/g, '<a href="$1">');
      content = content.replace(/\[\/LINK\]/g, "</a>");

      content = content
        .replaceAll(
          '<img src="web',
          '<img src="//static3.ticketapp.cl/uploads/web/web'
        )
        .replaceAll("<p>[/COL4]</p>", "</div>");

      content = content.replaceAll("<p></p>", "<br>");
      //}
      // transform content to vue template
      return content;
    },
  },
  methods: {
    gohome() {
      this.$router.push(this.getLink(""));
    },
    load() {
      if (this.info._id && !this.info.pages) {
        this.$router.push(this.getLink("menu"));
      }
      let { webName } = this.$store.state.info;
      let param = this.$store.state.isDomain
        ? this.$route.params.name
        : this.$route.params.id;

      if(webName == undefined) return false
      
      this.$http
        .get(`${webName}/page/${param || "welcome"}`)
        .then((res) => {
          this.statusCode = res.status
          this.page = res.data.data;
          document.title = `${this.page.title} - ${
            this.info.custom_name || this.info.name
          }`;
        })
        .catch((err) => {
          //this.$router.push(this.getLink("404"));
          this.statusCode = err.status
          console.error("Error al cargar la pagina");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    "$route.params.name": function () {
      console.log("change page name", this.$route.params.name);
      this.load();
      window.scroll(0, 0);
    },
    "$route.params.id": function () {
      console.log("change page id", this.$route.params.id);
      this.load();
    },
    info: function () {
      this.load();
    },
  },
};
</script>

<style>
.ql-align-justify {
  text-align: justify;
  display: block;
}
.ql-align-center {
  text-align: center;
  display: block;
  width: 100%;
}
</style>